var render = 0;

export function requisitarValor() {
  return render;
}

export function somarRender() {
  render = render + 1;
}

export function limparValor() {
  render = 0;
}
