import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import classNames from "classnames";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faArrowAltCircleRight
} from "@fortawesome/free-solid-svg-icons";

import Cookies from "js-cookie";

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarSearch = connect((store) => ({
  sidebar: store.sidebar,
}))(({ sidebar }) => {
  return (
    <Form inline>
      <Input
        type="text"
        placeholder=""
        aria-label="Search"
        className={classNames("form-control-lite", !sidebar.isOnRight || "text-right")}
      />
    </Form>
  );
});

const NavbarDropdowns = connect((store) => ({
  sidebar: store.sidebar,
}))(({ sidebar }) => {
  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        {/* <NavbarDropdown
          header="New Messages"
          footer="Show all messages"
          icon={faEnvelopeOpen}
          count={messages.length}
          active
        >
          {messages.map((item, key) => {
            return (
              <NavbarDropdownItem
                key={key}
                icon={
                  <img
                    className="avatar img-fluid rounded-circle"
                    src={item.avatar}
                    alt={item.name}
                  />
                }
                title={item.name}
                description={item.description}
                time={item.time}
                spacing
              />
            );
          })}
        </NavbarDropdown> */}

        {/* <NavbarDropdown
          header="New Notifications"
          footer="Show all notifications"
          icon={faBell}
          count={notifications.length}
          indicator
        >
          {notifications.map((item, key) => {
            let icon = <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />;

            if (item.type === "important") {
              icon = <FontAwesomeIcon icon={faBell} className="text-danger" />;
            }

            if (item.type === "login") {
              icon = <FontAwesomeIcon icon={faBuilding} className="text-primary" />;
            }

            if (item.type === "request") {
              icon = <FontAwesomeIcon icon={faBellSlash} className="text-success" />;
            }

            return (
              <NavbarDropdownItem
                key={key}
                icon={icon}
                title={item.title}
                description={item.description}
                time={item.time}
              />
            );
          })}
        </NavbarDropdown> */}

        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem>
              <FontAwesomeIcon icon={faUser} fixedWidth className="mr-2 align-middle" />
              View Profile
            </DropdownItem>
            <DropdownItem>
              <FontAwesomeIcon
                icon={faComments}
                fixedWidth
                className="mr-2 align-middle"
              />
              Contacts
            </DropdownItem>
            <DropdownItem>
              <FontAwesomeIcon
                icon={faChartPie}
                fixedWidth
                className="mr-2 align-middle"
              />
              Analytics
            </DropdownItem>
            <DropdownItem>
              <FontAwesomeIcon icon={faCogs} fixedWidth className="mr-2 align-middle" />
              Settings
            </DropdownItem>
            <DropdownItem divider /> */}
            <DropdownItem
              onClick={() => {
                Cookies.remove("token");
                window.location.href = "/";
              }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = ({ sidebar }) => {
  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          <NavbarSearch />
          <NavbarDropdowns />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarDropdowns />
          <NavbarSearch />
          <NavbarToggle />
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
}))(NavbarComponent);
