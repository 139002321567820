import React, { useEffect } from "react";
import "./editorMusica.css";

function ajustaPosicaoCifras() {
  var todaslinhas = document.querySelectorAll(".linhacifra");
  todaslinhas.forEach((linha) => {
    var todascifras = linha.querySelectorAll(".cifra");

    todascifras.forEach((cifra) => {
      var posicao = parseInt(cifra.getAttribute("posicao"));
      posicionaCifra(posicao - 1, cifra, false);
    });
  });
}

function posicionaCifra(posicao, elementoCifra, indicador) {
  const parentRect = elementoCifra.parentElement.getBoundingClientRect();
  var fakes = elementoCifra.parentElement.querySelectorAll(".fake");

  var left = 0;
  if (posicao > fakes.length - 1) posicao = fakes.length - 1;
  if (posicao === 0) left = fakes[0].getBoundingClientRect().left;
  if (posicao > 0) left = fakes[posicao - 1].getBoundingClientRect().right;
  elementoCifra.style.left = left - parentRect.left + "px";
  elementoCifra.style.top = "10px";

  if (indicador) {
    indicador = elementoCifra.getBoundingClientRect();
    document.getElementById("indicador").style.left = indicador.left - 3 + "px";
    document.getElementById("indicador").style.top = indicador.top + 15 + "px";
    document.getElementById("indicador").style.display = "block";
  }
}
let render = 0;
const EditorMusicaEstatico = ({ partes, setPartes }) => {
  useEffect(() => {
    if (partes.length > 0) {
      render = render + 1;
    }

    if (render === 1 && partes.length > 0) {
      for (let i = 0; i < partes.length; i++) {
        const styleElement = document.createElement("style");
        const parte = partes[i];
        styleElement.textContent = parte?.estilos;
        styleElement.id = parte?.id;
      }
    }

    setTimeout(() => {
      if (render === 1) {
        ajustaPosicaoCifras();
      }
    }, 10);

    return () => {
      render = 0;
    };
  }, [partes]);
  function renderizarSpans(palavra, linha, parte) {
    const cifras = linha.cifras;

    const linhaCifra = (
      <div
        style={{
          height: "30px",
          background: "rgb(255, 255, 221)",
          position: "relative",
        }}
        className={`divCifra-${parte.id} linhacifra ui-widget-content`}
      >
        {palavra.split("").map((letra, index) => (
          <span key={index} className="fake">
            {palavra.substring(0, index + 1)}
          </span>
        ))}

        {cifras.map((cifra, index) => (
          <span className="cifra" key={index} posicao={cifra.posicao}>
            {cifra.texto}
          </span>
        ))}
      </div>
    );

    return linhaCifra;
  }

  return (
    <div className="">
      {partes.map((parte, index) => (
        <div
          key={index}
          className="d-flex flex-column my-3 p-3 border rounded"
          style={{
            boxShadow: "10px 10px 5px #eeeeee",
          }}
        >
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                disabled
                style={{ background: "#fff" }}
                className="col-11 form-control"
                value={parte.title}
              />
            </div>
          </div>

          {parte?.linhas?.map((linha, key) => (
            <div className="my-3" key={key}>
              <div style={{ height: 30, background: "#ffd" }}>
                {renderizarSpans(linha.texto, linha, parte)}
              </div>
              <input
                style={{ border: 0, padding: 0 }}
                type="text"
                disabled
                value={linha.texto}
                className="w-100 linha-input"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export { EditorMusicaEstatico };
