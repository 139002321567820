import React, { useEffect, useState } from "react";
import { api } from "../../services";
import { Button } from "reactstrap";

const FormAdicionarMusicaRepertorio = () => {
  const [repertorios, setRepertorios] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const repertorios = await api.get("/Apresentacoes");
        setRepertorios(repertorios.data);
      } catch (err) {}
    })();
  }, []);
  return (
    <div>
      <h1>Adicionar música ao repertório:</h1>
      <select className="form-control my-2">
        {repertorios.map((repertorio, index) => (
          <option key={index}>{repertorio.descricao}</option>
        ))}
      </select>

      <input type="text" className="form-control my-2" placeholder="Tonalidade" />
      <Button color="primary" className="ml-1 mt-2 align-self-center">
        Adicionar
      </Button>
    </div>
  );
};

export { FormAdicionarMusicaRepertorio };
