import React, { useState } from "react";

import { Button, Card, CardBody, FormGroup, Label, Spinner } from "reactstrap";

import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";


const SignIn = () => {
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const { register, handleSubmit, watch } = useForm();

  async function realizarLogin(data) {
    console.log("submit ", data);
    const { email, senha } = data;

    try {
      setLoading(true);
      if (email === "ipbrecreio@gmail.com" && senha === "Abc,123") {
        Cookies.set("token", "123", { expires: 1 });
        Cookies.set("nome", "Secretaria", { expires: 1 });
        return history.push("/");
      }
      // const response = await api.post("/Usuario/autenticar", data);
      // const token = response?.data?.token;
      // const idUsuario = response?.data?.idUsuario;
      // const nome = response?.data?.nome;

      // if (token) {
      //   Cookies.set("token", "123", { expires: 1 });
      //   // Cookies.set("idUsuario", idUsuario, { expires: 1 });
      //   // Cookies.set("nome", nome, { expires: 1 });
      // }
      // setLoading(false);
      // history.push("/");
      else {
        alert("Acesso nao permitido");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Acesso nao permitido");
    }
  }

  console.log(watch("senha")); // watch input value by passing the name of it

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        {/* <h2 style={{ color: "#D75E0B" }}>IGREJA PRESBITERIANA DO RECREIO</h2> */}
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src="http://www.ipbrecreio.org.br/wp-content/uploads/2017/03/logo-150x150_transparente.png"
                alt="IPB"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div>
            <form onSubmit={handleSubmit(realizarLogin)}>
              <FormGroup>
                <Label>Email</Label>
                <input
                  {...register("email")}
                  className="form-control"
                  type="email"
                  placeholder="Entre com seu email"
                />
              </FormGroup>
              <FormGroup>
                <Label>Senha</Label>
                <input
                  className="form-control"
                  {...register("senha")}
                  type="password"
                  placeholder="Entre com sua senha"
                />
                {/* <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small> */}
              </FormGroup>
              <div>
                {/* <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              /> */}
              </div>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" type="submit">
                  {loading ? <Spinner color="light">Loading...</Spinner> : "Login"}
                </Button>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SignIn;
