import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
  Button,
} from "reactstrap";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { toastr } from "react-redux-toastr";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { api } from "../services";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { Igreja } from "./Igreja";

const tonalidades = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "F#", "G", "Ab"];

const DetalheRepertorio = () => {
  const history = useHistory();
  const { id } = useParams();
  const [repertorio, setRepertorio] = useState(null);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const response = await api.get(`/Apresentacoes/${id}`);
          console.log(response.data);

          setRepertorio(response.data);
        } catch (err) {
          console.log(err);
        }
      }
    })();
    //
  }, [id]);

  if (process.env.NODE_ENV === "production") {
    return <Igreja pagina="REPERTÓRIO" />;
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          <div className="d-flex justify-content-between">
            <span>Consultando Repertório {repertorio?.descricao || ""}</span>

            <div>
              <FontAwesomeIcon
                icon={faArrowLeft}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </HeaderTitle>
      </Header>

      <Row>
        <Col lg="12">
          <FormularioMusicasRepertorio
            repertorio={repertorio}
            setRepertorio={setRepertorio}
          />
        </Col>
      </Row>
    </Container>
  );
};

const FormularioMusicasRepertorio = ({ repertorio, setRepertorio }) => {
  const [musicas, setMusicas] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    (async () => {
      if (repertorio) {
        const response = await api.get("/Musicas");
        setMusicas(response.data);

        Object.entries(repertorio).forEach(([key, value]) => {
          setValue(key, value);
        });
      }
    })();
    // eslint-disable-next-line
  }, [repertorio]);

  const atualizarRepertorio = async (data) => {
    try {
      console.log("Dados válidos:", data);
      setLoading(true);

      await api.put(`/Apresentacoes/${data.apresentacaoId}`, data);
      setRepertorio(data);
      toastr.success("Apresentação atualizado com sucesso!", "", {
        position: "bottom-left",
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      const error = err.response.data.errors[0] || err.message;
      toastr.error("Erro ao manipular repertório!", error, {
        position: "bottom-left",
        timeOut: 15000,
      });
      setLoading(false);
    }
  };

  const moveMusic = (dragIndex, hoverIndex) => {
    const draggedMusic = musicas[dragIndex];
    setMusicas((prevMusicas) => {
      const updatedMusicas = [...prevMusicas];
      updatedMusicas.splice(dragIndex, 1);
      updatedMusicas.splice(hoverIndex, 0, draggedMusic);
      return updatedMusicas;
    });
  };

  return (
    <div>
      <Card>
        <CardBody>
          <div>
            <Form onSubmit={handleSubmit(atualizarRepertorio)}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Descrição</Label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      {...register("descricao")}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>Ativo</Label>
                    <select {...register("ativo")} className="form-control">
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                  </FormGroup>
                </Col>

                <Button color="primary" className="ml-3 mt-2 align-self-center">
                  {loading ? <Spinner color="light">Loading...</Spinner> : "Atualizar"}
                </Button>
              </Row>
            </Form>
          </div>
          <h1>Músicas</h1>
          LISTA DE TODAS AS MUSICAS MENOS DO REPERTORIO ATUAL
          <div className="row">
            <Col className="col-6">
              <div className="d-flex">
                <div>
                  {musicas.map((musica, index) => (
                    <div key={index}>
                      <span>{musica.nome}</span>
                      <br />
                    </div>
                  ))}
                </div>
                <button className="btn btn-primary mx-3" style={{ height: 35 }}>
                  <IoIosArrowForward size={28} />
                </button>
              </div>
            </Col>

            <div className="col-6">
              <DndProvider backend={HTML5Backend}>
                LISTA DE MÚSICA DOS REPERTÓRIOS
                <div className="d-flex">
                  <button
                    className="btn btn-primary mx-3 align-self-end mb-3"
                    style={{ height: 35 }}
                  >
                    <IoIosArrowBack size={28} />
                  </button>
                  <div>
                    {musicas.map((musica, index) => (
                      <MusicItem
                        key={index}
                        index={index}
                        musica={musica}
                        moveMusic={moveMusic}
                      />
                    ))}
                  </div>
                </div>
              </DndProvider>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetalheRepertorio;

const MusicItem = ({ musica, index, moveMusic }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "music",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "music",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveMusic(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <div ref={(node) => drop(drag(node))} style={{ opacity }}>
      <span>{musica.nome}</span>
    </div>
  );
};
