import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { api } from "../services";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { NovoEditorMusica } from "../componentes/NovoEditorMusica";
import { limparValor } from "../utils/render";
import { IoBookOutline } from "react-icons/io5";
import { ModalAuxiliar } from "../componentes/ModalAuxiliar/ModalAuxiliar";
import { FormAdicionarMusicaRepertorio } from "../componentes/FormRepertorio/FormAdicionarMusicaRepertorio";

const tonalidades = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "F#", "G", "Ab"];
export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CadastrarMusica = () => {
  const query = useQuery();
  const history = useHistory();
  const [musica, setMusica] = useState(null);
  const [repertorioModal, setRepertorioModal] = useState({ mostrar: false });

  useEffect(() => {
    (async () => {
      const musica = query.get("id");
      if (musica) {
        try {
          const response = await api.get(`/Musicas/${musica}`);
          console.log(response.data);

          setMusica(response.data);
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [query]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          <div className="d-flex justify-content-between">
            <span> {musica ? `Editando ${musica.nome}` : "Cadastrar Música"}</span>

            <div>
              <IoBookOutline
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => setRepertorioModal({ mostrar: true })}
              />
              <FontAwesomeIcon
                icon={faArrowLeft}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </HeaderTitle>
      </Header>

      <Row>
        <Col lg="12">
          <FormularioMusicas musica={musica} />
        </Col>
      </Row>
      <ModalAuxiliar
        data={repertorioModal}
        setData={setRepertorioModal}
        content={<FormAdicionarMusicaRepertorio />}
      />
    </Container>
  );
};

const FormularioMusicas = ({ musica }) => {
  const { register, handleSubmit, reset, setValue } = useForm({});
  const [loading, setLoading] = useState(false);
  const [partes, setPartes] = useState([]);

  useEffect(() => {
    (async () => {
      if (musica) {
        Object.entries(musica).forEach(([key, value]) => {
          if (value !== null) {
            setValue(key, value);
          }
          if (key === "dado") {
            setPartes(JSON.parse(value) || []);
          }
        });
      }
    })();

    return () => {
      limparValor();
    };
    // eslint-disable-next-line
  }, [musica]);

  const criarMusica = async (data) => {
    try {
      setLoading(true);
      const dataHTTP = {
        ...data,
        dado: JSON.stringify(partes),
      };
      if (musica) {
        await api.put(`/Musicas/${data.musicaId}`, dataHTTP);
        toastr.success("Música atualizada com sucesso!.", "", {
          position: "bottom-left",
        });
      } else {
        //cria
        const response = await api.post("/Musicas", dataHTTP);
        console.log(response);
        toastr.success("Música cadastrada com sucesso!.", "", {
          position: "bottom-left",
        });

        $("html, body").animate({ scrollTop: 0 }, "slow");
        reset();
        setPartes([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastr.error(err?.response?.data || err.message, "", {
        position: "bottom-left",
      });
      console.log(err?.response?.data);
      console.log(err);
    }
  };

  return (
    <Form onSubmit={handleSubmit(criarMusica)}>
      <Card>
        <CardHeader>
          <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
            MÚSICA
          </h4>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Nome</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("nome")}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Descrição</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("descricao")}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Tonalidade</Label>
                  <select
                    name=""
                    id=""
                    className="form-control"
                    {...register("tonalidade")}
                    required
                  >
                    <option value=""></option>

                    {tonalidades.map((tonalidade, index) => (
                      <option key={index} value={tonalidade}>
                        {tonalidade}
                      </option>
                    ))}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Tamanho da fonte*</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("tamanhoFonte")}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right ">Categoria*</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("categoria")}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-8 w-100 ">
              MÚSICA
              <Label sm={2} className="text-sm-right"></Label>
              <NovoEditorMusica partes={partes} setPartes={setPartes} />
            </div>
          </div>
        </CardBody>
      </Card>

      <FormGroup row>
        <button className="ml-3 btn btn-primary rounded shadow-lg font-weight-bold">
          {loading ? (
            <Spinner color="light">Loading...</Spinner>
          ) : musica ? (
            "Salvar"
          ) : (
            "Cadastrar"
          )}
        </button>
      </FormGroup>
    </Form>
  );
};

export default CadastrarMusica;
