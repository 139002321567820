import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from "jquery";

const ModalEstilos = ({ open, setOpen, partes, setPartes }) => {
  useEffect(() => {
    const estilos = open?.parte?.estilos;
    if (estilos !== "") {
      setTimeout(() => {
        $("#text-estilos").val(estilos);
      }, 100);
    }
  }, [open]);

  function criarEstilos() {
    const estilos = $("#text-estilos").val();
    console.log(estilos);
    let parte = open.parte;

    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);

    const novaParte = {
      ...parteAtualizada,
      estilos,
    };

    const partesAtualizadas = partesArray.map((parteRe) =>
      parteRe.id === parte.id ? novaParte : parteRe
    );

    const elementosStyle = document.getElementsByTagName("style");
    for (let i = 0; i < elementosStyle.length; i++) {
      const style = elementosStyle[i];

      if (style.id === open.parte.id) {
        style.parentNode.removeChild(style);
      }
    }

    setPartes(partesAtualizadas);
    const styleElement = document.createElement("style");
    styleElement.textContent = estilos;
    styleElement.id = open.parte.id;
    styleElement.className = "cssEstilos";
    document.head.appendChild(styleElement);
  }
  return (
    <Modal isOpen={open.show}>
      <ModalHeader>Editando estilos - {open?.parte?.id}</ModalHeader>
      <ModalBody className="text-center m-3">
        <textarea
          className=" w-100"
          name=""
          id="text-estilos"
          cols="30"
          rows="10"
        ></textarea>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setOpen({ parte: null, linha: null, show: false })}
          >
            Fechar
          </Button>{" "}
          <Button color="primary" type="button" onClick={() => criarEstilos()}>
            {open?.parte?.estilos === "" ? "Criar" : "Atualizar"}{" "}
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export { ModalEstilos };
