import React from "react";
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useLocation } from "react-router-dom";

const Igreja = ({ pagina }) => {
  const location = useLocation();
  const { pathname } = location;
  console.log();
  return (
    <Container fluid>
      <Header>
        <div className="d-flex justify-content-between mb-2">
          <HeaderTitle></HeaderTitle>
        </div>
      </Header>

      <Row>
        <Card className="w-100">
          <CardHeader>
            <h4 className="w-100 text-center">
              PÁGINA {pagina ? pagina : pathname.split("/")[2].toUpperCase()} EM
              CONSTRUÇÃO
            </h4>
          </CardHeader>
        </Card>
      </Row>
    </Container>
  );
};

export { Igreja };
