import React, { useEffect, useState } from "react";
import { cpf } from "cpf-cnpj-validator";
import Select from "react-select/async";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { api } from "../services";
import { useForm, Controller } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import user from "../../assets/img/user.png";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CadastrarPessoas = () => {
  const query = useQuery();
  const history = useHistory();
  const [usuario, setUsuario] = useState(null);
  const [tiposArrolamentos, setTiposArrolamentos] = useState([]);

  useEffect(() => {
    (async () => {
      const usuario = query.get("id");
      if (usuario) {
        try {
          const response = await api.get(`/Pessoas/${usuario}`);
          console.log(response.data);

          setUsuario(response.data);
        } catch (err) {
          console.log(err);
        }
      }
      const tiposArrolamentos = await api.get("/TiposArrolamentos");
      setTiposArrolamentos(tiposArrolamentos.data);
    })();
  }, [query]);

  return (
    <Container fluid>
      <Header>
        <div className="d-flex justify-content-between mb-2">
          <HeaderTitle>
            {" "}
            {usuario ? `Editando ${usuario.nome}` : "Cadastrar Pessoa"}
          </HeaderTitle>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={() => history.goBack()}
          >
            Voltar
          </button>
        </div>
      </Header>

      <Row>
        <Col lg="12">
          <FormularioPessoas usuario={usuario} tiposArrolamentos={tiposArrolamentos} />
        </Col>
      </Row>
    </Container>
  );
};

const FormularioPessoas = ({ usuario, tiposArrolamentos }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (usuario) {
        const { nome, cpf, dataNascimento, sexo } = usuario;
        $("#cpf").html(cpf);
        $("#sexo").html(sexo);
        $("#nome").html(nome);
        $("#nascimento").html(dataNascimento);

        const dataNascimentoMoment = moment(dataNascimento);
        const hoje = moment();
        const idadeAtual = hoje.diff(dataNascimentoMoment, "years");

        $("#idade").html(idadeAtual);
        $("#formulario-pessoal").hide();

        const datas = [
          "dataNascimento",
          "dataProfissaoDeFe",
          "dataBatismo",
          "recebidoMembro",
          "desligadoMembro",
        ];

        const responsePessoas = await api.get("/Pessoas");
        const pessoas = ["paiId", "maeId", "conjugeId"];

        Object.entries(usuario).forEach(([key, value]) => {
          if (value !== null) {
            if (datas.includes(key)) {
              const novoValor = moment(value).format("YYYY-MM-DD");
              console.log(key, novoValor);
              setValue(key, novoValor);
            } else if (pessoas.includes(key)) {
              const opcaoEncontrada = responsePessoas.data.find(
                (item) => item.id === value
              );

              if (opcaoEncontrada) {
                setValue(key, { value: opcaoEncontrada.id, label: opcaoEncontrada.nome });
              }
            } else {
              setValue(key, value);
            }
          }
        });
      }
    })();
    // eslint-disable-next-line
  }, [usuario]);

  const criarPessoa = async (data) => {
    try {
      if (data.cep === "") {
        data.cep = 0;
      }

      if (data.tipoArrolamentoId === "") {
        data.tipoArrolamentoId = null;
      }

      if (data.dataProfissaoDeFe === "") {
        data.dataProfissaoDeFe = null;
      }

      if (data.dataBatismo === "") {
        data.dataBatismo = null;
      }

      if (data.recebidoMembro === "") {
        data.recebidoMembro = null;
      }

      if (data.desligadoMembro === "") {
        data.desligadoMembro = null;
      }

      if (data.numero === "") {
        data.numero = 0;
      }

      if (data.conjugeId) {
        data.conjugeId = data.conjugeId.value;
      }

      if (data.maeId) {
        data.maeId = data.maeId.value;
      }

      if (data.paiId) {
        data.paiId = data.paiId.value;
      }

      // if (data.telefoneCelular) {
      //   data.telefoneCelular = data.telefoneCelular.replace(/\D/g, "");
      // }
      setLoading(true);
      if (usuario) {
        // atualiza
        await api.put(`/Pessoas/${data.id}`, data);
        toastr.success("Pessoa atualizada com sucesso!.", "", {
          position: "bottom-left",
        });
      } else {
        //cria
        const response = await api.post("/Pessoas", data);
        console.log(response);
        toastr.success("Pessoa cadastrada com sucesso!.", "", {
          position: "bottom-left",
        });

        $("#sexo").html("");
        $("#nome").html("");
        $("#idade").html("");
        $("#cpf").html("");
        $("#nascimento").html("");
        $("html, body").animate({ scrollTop: 0 }, "slow");
        reset();
      }
      setLoading(false);
    } catch (err) {
      const errorCpf = err?.response?.data;
      var mensagem = "";
      if (errorCpf === "CPF já cadastrado") {
        mensagem = errorCpf;
      } else {
        mensagem = "Nao foi possível cadastrar a pessoa, verifique os campos digitados!.";
      }
      setLoading(false);
      toastr.error(mensagem, "", {
        position: "bottom-left",
      });
      console.log(err?.response?.data);
      console.log(err);
    }
  };

  return (
    <Form onSubmit={handleSubmit(criarPessoa)}>
      <Card>
        <CardHeader>
          <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
            Perfil
          </h4>
        </CardHeader>
        <CardBody>
          <div className="d-flex">
            <FormGroup row className="align-items-center">
              <div className="col-auto mr-3">
                <div className="sw-6 sw-xl-14">
                  <img src={user} width={80} className="rounded-circle" alt="thumb" />
                </div>
              </div>

              <div className="d-flex flex-column">
                <div className="d-flex flex-column mr-3 mb-3">
                  <input
                    style={{ background: "#fff", border: 0, outline: "none" }}
                    id="nome"
                    readOnly
                    {...register("nome", { required: true })}
                  />

                  <span id="cpf"></span>
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-column mr-3">
                    <span>DATA NASCIMENTO</span>
                    <span style={{ background: "#fff", border: 0 }} id="nascimento">
                      -
                    </span>
                  </div>

                  <div className="d-flex flex-column mr-3">
                    <span>IDADE</span>
                    <span id="idade">-</span>
                  </div>

                  <div className="d-flex flex-column mr-3">
                    <span>SEXO</span>
                    <input
                      id="genero"
                      {...register("sexo")}
                      required
                      readOnly
                      style={{ background: "#fff", border: 0 }}
                    ></input>
                  </div>
                </div>

                <div className="d-flex flex-column mr-3 mt-3">
                  <span>Observação</span>
                  <input
                    className="form-control"
                    id="genero"
                    {...register("observacao")}
                  ></input>
                </div>
              </div>
            </FormGroup>
          </div>
        </CardBody>
      </Card>

      <InformacoesPessoais
        register={register}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
      />
      <Contato register={register} />
      <Endereco
        register={register}
        setValue={setValue}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        control={control}
      />
      <Familia register={register} setValue={setValue} control={control} />
      <Eclesia register={register} tiposArrolamentos={tiposArrolamentos} />

      <FormGroup row>
        <button className="ml-3 btn btn-primary rounded shadow-lg font-weight-bold">
          {loading ? (
            <Spinner color="light">Loading...</Spinner>
          ) : usuario ? (
            "Salvar"
          ) : (
            "Cadastrar"
          )}
        </button>
      </FormGroup>
    </Form>
  );
};

const InformacoesPessoais = ({ register, getValues, setValue, errors }) => {
  const [loading, setLoading] = useState(false);

  const buscar = async () => {
    let [cpfForm, dataNascimentoForm] = getValues(["cpf", "dataNascimento"]);
    cpfForm = cpfForm.replace(/\D/g, "");

    if (!cpf.isValid(cpfForm)) {
      return alert("CPF inválido");
    }

    var padraoData = /^\d{4}-\d{2}-\d{2}$/;
    if (!dataNascimentoForm || !padraoData.test(dataNascimentoForm)) {
      return alert("Data de nascimento inválido");
    }

    dataNascimentoForm = moment(dataNascimentoForm).format("DDMMYYYY");
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.sintegraws.com.br/api/v1/execute-api.php?token=E0CE618F-0AB3-4868-879C-CC14391DFF4B&cpf=${cpfForm}&data-nascimento=${dataNascimentoForm}&plugin=CPF`
      );
      setLoading(false);
      const mensagemSucesso = "Pesquisa realizada com sucesso.";
      if (response.data.message !== mensagemSucesso) {
        return alert(response.data.message);
      }
      console.log(response.data);

      const {
        nome,
        cpf,
        data_nascimento: dataNascimento,
        genero: sexo,
        idade,
      } = response.data;

      $("#nome").html(nome);
      $("#cpf").html(cpf);
      $("#sexo").html(sexo);
      $("#nascimento").html(dataNascimento);
      $("#idade").html(idade);

      setValue("nome", nome);
      setValue("sexo", sexo);
    } catch (err) {
      setLoading(false);
      console.log(err);
      console.log(err.message);
      alert(err.response.data.message || err.message);
    }
  };

  return (
    <Card id="formulario-pessoal">
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          Informações Pessoais
        </h4>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            CPF
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              type="number"
              {...register("cpf")}
              placeholder="7777777766"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Data de nascimento
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              type="date"
              placeholder=""
              required
              {...register("dataNascimento")}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={{ size: 10, offset: 2 }}>
            <div className="d-flex flex-column">
              {errors.nome && (
                <span className="text-danger">Realize a busca pessoal.</span>
              )}
            </div>

            <Button type="button" onClick={() => buscar()} color="primary">
              {loading ? <Spinner color="light">Loading...</Spinner> : "Buscar"}
            </Button>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
const Contato = ({ register }) => {
  return (
    <Card>
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          Contato
        </h4>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Email
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              type="email"
              {...register("email")}
              placeholder=""
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Telefone Celular
          </Label>
          <Col sm={10}>
            <input
              className="form-control  telefoneCelular"
              type="text"
              onInput={(event) => {
                /*
                const originalValue = event.target.value; 

                if(event.target.value.length > 13) return event.target.value =  originalValue;

                let inputValue = event.target.value;
                inputValue = inputValue.replace(/\D/g, '');
                const formattedValue = formatPhoneNumber(inputValue);
            
                event.target.value = formattedValue || inputValue;
                */
              }}
              onKeyUp={(event) => console.log(event.target.value)}
              {...register("telefoneCelular")}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Telefone Residencial
          </Label>
          <Col sm={10}>
            <input
              className="form-control telefoneResidencial"
              type="text"
              {...register("telefoneResidencial")}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
const Endereco = ({ register, setValue, errors, setError, clearErrors, control }) => {
  const buscaCEP = async (cep) => {
    try {
      const fields = ["endereco", "bairro", "cidade", "estado"];
      for (let i = 0; i < fields.length; i++) {
        setValue(fields[i], "");
      }
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (response.data.erro) {
        return setError("cep", {
          type: "manual",
          message: "CEP inválido!",
        });
      }
      if (response.data) {
        setValue("endereco", response.data.logradouro);
        setValue("bairro", response.data.bairro);
        setValue("cidade", response.data.localidade);
        setValue("estado", response.data.uf);
      }

      if (errors?.cep?.message === "CEP inválido!") {
        console.log(errors);
        clearErrors("cep");
      }
      clearErrors("endereco");
    } catch (error) {
      console.log(error.message);
      toastr.info("Erro ao consultar o CEP.", "", { position: "bottom-left" });
    }
  };
  return (
    <Card>
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          Endereço
        </h4>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            CEP
          </Label>
          <Col sm={10}>
            <Controller
              control={control}
              name="cep"
              render={({ field }) => (
                <input
                  {...field}
                  className="form-control"
                  type="number"
                  placeholder=""
                  onBlur={(e) => {
                    if (e.target.value.length > 0 && e.target.value.length !== 8) {
                      setError("cep", {
                        type: "manual",
                        message: "CEP inválido!",
                      });

                      if (e.target.value.length === 1 && errors.cep) {
                      }
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 8) {
                      buscaCEP(e.target.value);
                    }
                  }}
                />
              )}
              rules={{
                required: false,
                pattern: {
                  value: /^\d{8}$/,
                  message: "Formato de CEP inválido (ex: 24941680)",
                },
              }}
            />
            {errors.cep && <span className="text-danger">{errors.cep.message}</span>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Endereço
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              type="text"
              placeholder=""
              readOnly
              {...register("endereco")}
            />
            {errors?.endereco && (
              <span className="text-danger">Informe um CEP válido!</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Número
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              placeholder=""
              type="number"
              {...register("numero")}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            complemento
          </Label>
          <Col sm={10}>
            <input className="form-control" placeholder="" {...register("complemento")} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Bairro
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              placeholder=""
              required
              readOnly
              {...register("bairro")}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Cidade
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              placeholder=""
              readOnly
              {...register("cidade")}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Estado
          </Label>
          <Col sm={10}>
            <input
              className="form-control"
              placeholder=""
              readOnly
              {...register("estado")}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

const Familia = ({ register, setValue, control }) => {
  const loadOptions = async (inputValue) => {
    const response = await api.post("/Pessoas/nome", inputValue, {
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
    });

    const options = response.data.map((item) => ({ value: item.id, label: item.nome }));
    return options;
  };

  return (
    <Card>
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          Família
        </h4>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Estado Cívil
          </Label>
          <Col sm={10}>
            <select {...register("estadoCivil")} className="form-control" placeholder="">
              <option value=""></option>
              <option value="SOLTEIRO">Solteiro</option>
              <option value="CASADO">Casado</option>
              <option value="SEPARADO">Separado</option>
              <option value="VIUVO">Viúvo</option>
            </select>
          </Col>
        </FormGroup>{" "}
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Pai
          </Label>
          <Col sm={10}>
            <Controller
              name="paiId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  loadOptions={loadOptions}
                  onChange={(selectedOption) => setValue("paiId", selectedOption)}
                  isClearable
                />
              )}
            />
          </Col>
        </FormGroup>{" "}
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Mãe
          </Label>
          <Col sm={10}>
            <Controller
              name="maeId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  loadOptions={loadOptions}
                  onChange={(selectedOption) => setValue("maeId", selectedOption)}
                  isClearable
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Conjugue
          </Label>
          <Col sm={10}>
            <Controller
              name="conjugeId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  loadOptions={loadOptions}
                  onChange={(selectedOption) => setValue("conjugeId", selectedOption)}
                  isClearable
                />
              )}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

const Eclesia = ({ register, tiposArrolamentos }) => (
  <Card>
    <CardHeader>
      <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
        Eclesia
      </h4>
    </CardHeader>
    <CardBody>
      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Ata admissão
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            type="text"
            {...register("atadeAdmissao")}
            placeholder=""
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Tipo arrolamento
        </Label>
        <Col sm={10}>
          <select
            {...register("tipoArrolamentoId")}
            className="form-control"
            placeholder=""
          >
            <option value=""></option>
            {tiposArrolamentos.map((item, indice) => (
              <option value={item.id} key={indice}>
                {item.tipo}
              </option>
            ))}
          </select>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Igreja Anterior
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            placeholder=""
            {...register("igrejaAnterior")}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Data Profissão de Fé
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            placeholder=""
            type="date"
            {...register("dataProfissaoDeFe")}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Data Batismo
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            placeholder=""
            type="date"
            {...register("dataBatismo")}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Recebido Em
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            placeholder=""
            type="date"
            {...register("recebidoMembro")}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={2} className="text-sm-right">
          Desligado Em
        </Label>
        <Col sm={10}>
          <input
            className="form-control"
            placeholder=""
            type="date"
            {...register("desligadoMembro")}
          />
        </Col>
      </FormGroup>
    </CardBody>
  </Card>
);
export default CadastrarPessoas;
