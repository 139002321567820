import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from "jquery";
import "./editorMusica.css";
import { ModalEstilos } from "./ModalEstilos";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import { limparValor, requisitarValor, somarRender } from "../utils/render";

let clickCount = 0;
let render = 0;

let fakePositions = [];
let drag = false;
let initialMouseX = 0;
let initialSpanX = 0;
let cifraSelecionada;
let currentMousePosition = 0;
let draggable;
let parteLinhaCifra = null;

function ajustaPosicaoCifras() {
  var todaslinhas = document.querySelectorAll(".linhacifra");
  todaslinhas.forEach((linha) => {
    var todascifras = linha.querySelectorAll(".cifra");

    todascifras.forEach((cifra) => {
      var posicao = parseInt(cifra.getAttribute("posicao"));
      posicionaCifra(render === 1 ? posicao - 1 : posicao, cifra, false);
    });
  });
}

function posicionaCifra(posicao, elementoCifra, indicador) {
  if (requisitarValor() === 1) {
    posicao = posicao - 1;
  }
  posicao = posicao === -1 ? 0 : posicao;
  const parentRect = elementoCifra.parentElement.getBoundingClientRect();
  var fakes = elementoCifra.parentElement.querySelectorAll(".fake");

  var left = 0;
  if (posicao > fakes.length - 1) posicao = fakes.length - 1;
  if (posicao === 0) left = fakes[0].getBoundingClientRect().left;
  if (posicao > 0) left = fakes[posicao - 1].getBoundingClientRect().right;
  elementoCifra.style.left = left - parentRect.left + "px";
  elementoCifra.style.top = "10px";
}

function cifras() {
  const cifras = document.querySelectorAll(".cifra");

  cifras.forEach((cifraSpan) => {
    cifraSpan.addEventListener("mousedown", function (e) {
      fakePositions = [];
      drag = true;
      draggable = this;
      initialMouseX = e.clientX;
      initialSpanX = parseInt(window.getComputedStyle(cifraSpan).left, 10);

      cifraSelecionada = cifraSpan;
      //console.log(cifraSelecionada);
      const fakes = draggable.parentNode.querySelectorAll(".fake");
      let i0 = 0;
      fakes.forEach((fake) => {
        const rect = fake.getBoundingClientRect();
        if (i0 === 0) fakePositions.push(rect.left);
        fakePositions.push(rect.right);
        i0++;
      });
    });
  });
}

function botaoUp(e) {
  if (drag) {
    drag = false;
    draggable.style.cursor = "pointer";
  }
}

const NovoEditorMusica = ({ partes, setPartes }) => {
  const [open, setOpen] = useState(false);
  const [openEstilos, setOpenEstilos] = useState(false);
  const [mostrarGrade, setMostrarGrade] = useState(false);

  useEffect(() => {
    if (partes.length > 0) {
      somarRender();
      console.log(requisitarValor());
    }

    if (requisitarValor() === 1 && partes.length > 0) {
      for (let i = 0; i < partes.length; i++) {
        const styleElement = document.createElement("style");
        const parte = partes[i];
        styleElement.textContent = parte?.estilos;
        styleElement.id = parte?.id;
      }
    }
    setTimeout(() => {
      if (requisitarValor() === 1) {
        console.log("entrou");
        ajustaPosicaoCifras();
      }
      $(".linhacifra").each(function () {
        $(this)
          .find(".cifra")
          .draggable({
            containment: $(this),
          });
      });

      $(".fake").droppable({
        drop: function (event, ui) {
          var elementoDroppable = $(this);

          var mouseX = event.clientX;
          var fakes = elementoDroppable.find(".fake");
          var i0 = 0;
          fakes.each(function () {
            var rect = this.getBoundingClientRect();
            if (i0 === 0) fakePositions.push(rect.left);
            fakePositions.push(rect.right);
            i0++;
          });

          var newPosition = 0;
          for (var i = 0; i < fakePositions.length; i++) {
            if (mouseX >= fakePositions[i]) {
              newPosition = i + 1;
            }
          }
          cifraSelecionada.posicao = newPosition - 1;
          posicionaCifra(cifraSelecionada.posicao, draggable, true);

          let { parte, linha, cifraId } = parteLinhaCifra;

          const partesArray = [...partes];

          const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);
          $(cifraSelecionada).attr("posicao", newPosition);
          const novaParte = {
            ...parteAtualizada,
            linhas: parteAtualizada.linhas.map((linhaRe) =>
              linhaRe.id === linha.id
                ? {
                    ...linhaRe,
                    cifras: linhaRe.cifras.map((cifra) => {
                      if (cifra.id === cifraId) {
                        cifra.posicao = newPosition === 0 ? 1 : newPosition;
                      }
                      return cifra;
                    }),
                  }
                : linhaRe
            ),
          };

          const partesAtualizadas = partesArray.map((parteRe) =>
            parteRe.id === parte.id ? novaParte : parteRe
          );

          setPartes(partesAtualizadas);
        },
      });
      cifras();
    }, 10);
  }, [partes, setPartes]);

  function adicionarParte() {
    setPartes([
      ...partes,
      {
        id: window.crypto.randomUUID(),
        title: "",
        estilos: "",
        linhas: [
          {
            id: window.crypto.randomUUID(),
            texto: "",
            cifras: [{ id: window.crypto.randomUUID(), texto: "", posicao: 0 }],
          },
        ],
      },
    ]);
  }

  function adicionarLinha(parte) {
    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);

    const novaParte = {
      ...parteAtualizada,
      linhas: [
        ...parteAtualizada.linhas,
        {
          id: window.crypto.randomUUID(),
          texto: "",
          cifras: [{ id: window.crypto.randomUUID(), texto: "", posicao: 0 }],
        },
      ],
    };

    const partesAtualizadas = partesArray.map((parteRe) =>
      parteRe.id === parte.id ? novaParte : parteRe
    );

    setPartes(partesAtualizadas);
  }
  function alterarTitulo(titulo, parte) {
    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);

    const novaParte = {
      ...parteAtualizada,
      title: titulo,
    };

    const partesAtualizadas = partesArray.map((parteRe) =>
      parteRe.id === parte.id ? novaParte : parteRe
    );

    setPartes(partesAtualizadas);
  }

  function alterarLinha(titulo, parte, linhaId) {
    const partesArray = [...partes];
    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);

    const novaParte = {
      ...parteAtualizada,
      linhas: parteAtualizada.linhas.map((linha) =>
        linha.id === linhaId ? { ...linha, texto: titulo } : linha
      ),
    };

    const partesAtualizadas = partesArray.map((parteRe) =>
      parteRe.id === parte.id ? novaParte : parteRe
    );

    setPartes(partesAtualizadas);
  }

  const handleClick = (parte, linha, posicao) => {
    clickCount++;
    if (clickCount === 1) {
      setTimeout(() => {
        if (clickCount === 2) {
          setOpen({ parte, linha, show: true, posicao: posicao || null });
        }

        clickCount = 0;
      }, 300);
    }
  };

  const handleClickExcluirLinha = (parte, linha) => {
    clickCount++;
    if (clickCount === 1 || clickCount === 2) {
      setTimeout(() => {
        if (clickCount === 3) {
          if (!window.confirm("Excluir linha?")) {
            clickCount = 0;
            return 0;
          }
          console.log("Excluir");
          const partesArray = [...partes];
          const novasPartes = partesArray.map((parteItem) => {
            if (parteItem.id === parte.id) {
              const novasLinhas = parteItem.linhas.filter(
                (linhaRe) => linhaRe.id !== linha.id
              );
              return { ...parteItem, linhas: novasLinhas };
            }
            return parteItem;
          });

          setPartes(novasPartes);
        }

        clickCount = 0;
      }, 500);
    }
  };

  const removerParte = (parte) => {
    if (!window.confirm("Deseja excluir a parte?")) return 0;
    let partesArray = [...partes];
    partesArray = partesArray.filter((pt) => pt.id !== parte.id);
    setPartes(partesArray);
  };

  function renderizarSpans2(palavra, linha, parte) {
    const cifras = linha.cifras;

    const linhaCifra = (
      <div
        style={{
          height: "30px",
          background: "rgb(255, 255, 221)",
          position: "relative",
        }}
        className={`divCifra-${parte.id} linhacifra ui-widget-content`}
        onMouseUp={(e) => {
          botaoUp(e);
        }}
        onMouseMove={(e) => {
          currentMousePosition = e.clientX;
        }}
      >
        {palavra.split("").map((letra, index) => (
          <span key={index} className="fake" style={{ opacity: mostrarGrade ? 1 : 0 }}>
            {palavra.substring(0, index + 1)}
          </span>
        ))}

        {cifras.map((cifra, index) => (
          <span
            className="cifra"
            key={index}
            posicao={cifra.posicao}
            onMouseDown={(e) => {
              parteLinhaCifra = { parte, linha, cifraId: cifra.id };
            }}
            onMouseUp={(e) => {
              botaoUp(e);
            }}
            onClick={(e) => {
              e.stopPropagation();

              setOpen({ parte, linha, cifra, show: true });
              console.log("clique cifra");
            }}
          >
            {cifra.texto}
          </span>
        ))}
      </div>
    );

    return linhaCifra;
  }

  return (
    <div className="border p-3">
      <div className="d-flex justify-content-between">
        <button type="button" onClick={() => adicionarParte()}>
          + PARTE
        </button>

        <div className="d-flex algign-items-center">
          <label className="mb-0" htmlFor="">
            Mostrar grades
          </label>
          <input
            type="checkbox"
            className="form-check mx-1"
            value={mostrarGrade}
            onChange={() => setMostrarGrade((mostrar) => !mostrarGrade)}
          />
        </div>
      </div>

      {partes.map((parte, index) => (
        <div
          key={index}
          className={`d-flex flex-column my-3 p-3 border rounded divParte-${parte?.id}`}
          id={parte.id}
        >
          <pre style={{ width: 400, whiteSpace: "pre-wrap" }}>
            {process.env.NODE_ENV === "development" && JSON.stringify(parte)}
          </pre>
          <button
            type="button"
            style={{ width: 90, marginBottom: 20 }}
            onClick={() => setOpenEstilos({ parte, show: true })}
          >
            Estilos
          </button>
          <div className="row">
            <div className="col-1">
              <button
                onClick={() => adicionarLinha(parte)}
                className=" btn btn-primary mr-2"
                type="button"
              >
                +
              </button>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="col-11 form-control"
                value={parte.title}
                onChange={(e) => {
                  alterarTitulo(e.target.value, parte);
                }}
              />
            </div>
            <div className="col-1">
              <button
                onClick={() => removerParte(parte)}
                className=" btn btn-danger mr-2"
                type="button"
              >
                -
              </button>
            </div>
          </div>

          {parte?.linhas?.map((linha, key) => (
            <div className="my-3" key={key}>
              <div
                onClick={() => (linha.texto.length > 0 ? handleClick(parte, linha) : "")}
              >
                {renderizarSpans2(linha.texto, linha, parte)}
              </div>
              <input
                type="text"
                value={linha.texto}
                style={{ border: 0, borderBottom: "solid 2px black", padding: 0 }}
                className={`w-100 linha-input inputLinha-${parte.id}`}
                onClick={() => handleClickExcluirLinha(parte, linha)}
                onChange={(e) => alterarLinha(e.target.value, parte, linha.id)}
              />
            </div>
          ))}
        </div>
      ))}
      <ModalAcorde open={open} setOpen={setOpen} partes={partes} setPartes={setPartes} />
      <ModalEstilos
        open={openEstilos}
        setOpen={setOpenEstilos}
        partes={partes}
        setPartes={setPartes}
      />
    </div>
  );
};

const ModalAcorde = ({ open, setOpen, partes, setPartes }) => {
  useEffect(() => {
    console.log(open);
    if (open.cifra) {
      const cifra = open.cifra;
      setTimeout(() => {
        $("#input-acorde").val(cifra.texto);
      }, 100);
    }
  }, [open]);

  function editarCifra() {
    const data = $("#input-acorde").val();
    const parte = open.parte;
    const linha = open.linha;
    const cifra = open.cifra;

    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);
    if (!parteAtualizada) {
      console.error("Parte não encontrada");
      return;
    }

    const linhaAtualizada = parteAtualizada.linhas.find(
      (linhaRe) => linhaRe.id === linha.id
    );
    if (!linhaAtualizada) {
      console.error("Linha não encontrada");
      return;
    }

    linhaAtualizada.cifras = linhaAtualizada.cifras.map((cifraRe) => {
      if (cifraRe.id === cifra.id) {
        return { ...cifraRe, texto: data };
      } else {
        return cifraRe;
      }
    });

    const partesAtualizadas = partesArray.map((parteRe) => {
      if (parteRe.id === parte.id) {
        return parteAtualizada;
      } else {
        return parteRe;
      }
    });

    setPartes(partesAtualizadas);
    setOpen({ parte: null, linha: null, show: false });
  }

  function excluirCifra() {
    const parte = open.parte;
    const linha = open.linha;
    const cifra = open.cifra;

    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);
    if (!parteAtualizada) {
      console.error("Parte não encontrada");
      return;
    }

    const linhaAtualizada = parteAtualizada.linhas.find(
      (linhaRe) => linhaRe.id === linha.id
    );
    if (!linhaAtualizada) {
      console.error("Linha não encontrada");
      return;
    }

    linhaAtualizada.cifras = linhaAtualizada.cifras.filter(
      (cifraRe) => cifraRe.id !== cifra.id
    );

    const partesAtualizadas = partesArray.map((parteRe) => {
      if (parteRe.id === parte.id) {
        return parteAtualizada;
      } else {
        return parteRe;
      }
    });

    setPartes(partesAtualizadas);
    setOpen({ parte: null, linha: null, show: false });
  }

  function salvar() {
    const data = $("#input-acorde").val();
    let parte = open.parte;
    let linha = open.linha;

    const partesArray = [...partes];

    const parteAtualizada = partesArray.find((parteRe) => parteRe.id === parte.id);

    const novaParte = {
      ...parteAtualizada,
      linhas: parteAtualizada.linhas.map((linhaRe) =>
        linhaRe.id === linha.id
          ? {
              ...linhaRe,
              cifras: linhaRe.cifras.concat({
                texto: data,
                posicao: open.posicao || linhaRe.cifras.length,
                id: window.crypto.randomUUID(),
              }),
            }
          : linhaRe
      ),
    };

    const partesAtualizadas = partesArray.map((parteRe) =>
      parteRe.id === parte.id ? novaParte : parteRe
    );

    setPartes(partesAtualizadas);
  }

  return (
    <Modal isOpen={open.show}>
      <ModalHeader>{open?.cifra ? "Atualizar" : "Adicionar"} Acorde(s)</ModalHeader>
      <ModalBody className="text-center m-3">
        <input type="text" className="form-control" id="input-acorde" />
        <ModalFooter>
          {open?.cifra && (
            <Button color="danger" onClick={() => excluirCifra()}>
              Excluir
            </Button>
          )}
          <Button
            color="secondary"
            onClick={() => setOpen({ parte: null, linha: null, show: false })}
          >
            Fechar
          </Button>{" "}
          <Button
            color="primary"
            type="button"
            onClick={() => (open?.cifra ? editarCifra() : salvar())}
          >
            {open?.cifra ? "Atualizar" : "Salvar"}{" "}
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export { NovoEditorMusica };
