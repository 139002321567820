import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import user from "../../assets/img/user.png";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { api } from "../services";
import { RefreshCw } from "react-feather";
import moment from "moment";

const ListaDadosPessoa = ({ pessoa }) => {
  const [pessoas, setPessoas] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/Pessoas");
      setPessoas(response.data);
    })();
  }, []);

  function calcularIdade(nascimento) {
    if (nascimento) {
      const dataNascimentoMoment = moment(nascimento);
      const hoje = moment();
      const idadeAtual = hoje.diff(dataNascimentoMoment, "years");
      return idadeAtual;
    }
  }

  function verificarNomeFamilia() {
    if (pessoas) {
      console.log(pessoas);
      const pessoasKey = ["paiId", "maeId", "conjugeId"];

      let rows = [];
      Object.entries(pessoa).forEach(([key, value]) => {
        if (pessoasKey.includes(key)) {
          const opcaoEncontrada = pessoas.find((item) => item.id === value);

          const labelSemId = key.split("Id")[0];
          rows.push(
            <tr key={key}>
              <th>{labelSemId.charAt(0).toUpperCase() + labelSemId.slice(1)}</th>
              <td>{opcaoEncontrada ? opcaoEncontrada.nome : ""}</td>
            </tr>
          );
        }
      });
      console.log(rows);
      return rows;
    }
  }

  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <RefreshCw />
          </span>
        </div>
        <CardTitle tag="h5" className="mb-0">
          {pessoa.nome}
          {pessoa.recebidoMembro ? (
            <span className="ml-2 badge badge-primary">MEMBRO</span>
          ) : (
            ""
          )}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row noGutters>
          <Col sm="3" xl="12" className="col-xxl-4 text-center">
            <img
              src={user}
              width="128"
              height="128"
              className="rounded-circle mt-2"
              alt=""
            />
          </Col>
          <Col sm="9" xl="12" className="col-xxl-8">
            <strong>Sobre</strong>
            <p>{pessoa?.observacao}.</p>
          </Col>
        </Row>

        <Table size="sm" className="my-2">
          <tbody>
            {pessoa?.nome && (
              <>
                <tr>
                  <th>Nome</th>
                  <td>{pessoa?.nome}</td>
                </tr>
                <tr>
                  <th>CPF</th>
                  <td>{pessoa?.cpf}</td>
                </tr>
                <tr>
                  <th>Sexo</th>
                  <td>{pessoa?.sexo}</td>
                </tr>
                <tr>
                  <th>Data Nascimento</th>
                  <td>
                    {pessoa?.dataNascimento &&
                      moment(pessoa.dataNascimento).format("DD/MM/YYYY")}
                  </td>
                </tr>
                <tr>
                  <th>Idade</th>
                  <td>{calcularIdade(pessoa?.dataNascimento)}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{pessoa?.email}</td>
                </tr>
                <tr>
                  <th>Celular</th>
                  <td>{pessoa?.telefoneCelular}</td>
                </tr>
                <tr>
                  <th>Residencial</th>
                  <td>{pessoa?.telefoneResidencial}</td>
                </tr>
                <tr>
                  <th>CEP</th>
                  <td>{pessoa?.cep}</td>
                </tr>
                <tr>
                  <th>Endereço</th>
                  <td>{pessoa?.endereco}</td>
                </tr>
                <tr>
                  <th>Número</th>
                  <td>{pessoa?.numero}</td>
                </tr>
                <tr>
                  <th>Complemento</th>
                  <td>{pessoa?.complemento}</td>
                </tr>
                <tr>
                  <th>Bairro</th>
                  <td>{pessoa?.bairro}</td>
                </tr>
                <tr>
                  <th>Cidade</th>
                  <td>{pessoa?.cidade}</td>
                </tr>
                <tr>
                  <th>Estado</th>
                  <td>{pessoa?.estado}</td>
                </tr>
                <tr>
                  <th>Estado Civil</th>
                  <td>{pessoa?.estadoCivil}</td>
                </tr>
                {pessoas.length > 0 && verificarNomeFamilia()}
                <tr>
                  <th>Ata admissão</th>
                  <td>{pessoa?.atadeAdmissao}</td>
                </tr>
                <tr>
                  <th>Tipo arrolamento</th>
                  <td>{pessoa?.tipoArrolamentoId}</td>
                </tr>
                <tr>
                  <th>Igreja Anterior</th>
                  <td>{pessoa?.igrejaAnterior}</td>
                </tr>
                <tr>
                  <th>Data da Profissão de fé</th>
                  <td>
                    {pessoa?.dataProfissaoDeFe &&
                      moment(pessoa?.dataProfissaoDeFe).format("DD/MM/YYYY")}
                  </td>
                </tr>
                <tr>
                  <th>Data de Batismo</th>
                  <td>
                    {pessoa?.dataBatismo &&
                      moment(pessoa?.dataBatismo).format("DD/MM/YYYY")}
                  </td>
                </tr>
                <tr>
                  <th>Recebido Em</th>
                  <td>
                    {pessoa?.recebidoMembro &&
                      moment(pessoa?.recebidoMembro).format("DD/MM/YYYY")}
                  </td>
                </tr>
                <tr>
                  <th>Desligado Em</th>
                  <td>
                    {pessoa?.desligadoMembro &&
                      moment(pessoa?.recebidoMembro).format("DD/MM/YYYY")}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const DetalhePessoa = () => {
  const [pessoa, setPessoa] = useState({});
  const [pessoas, setPessoas] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  const buscarDadosPessoa = async () => {
    try {
      const pessoas = await api.get(`/Pessoas`);
      let pessoaResponse = await api.get(`/Pessoas/${id}`);
      const responseArrolamentos = await api.get("/TiposArrolamentos");
      const tiposArrolamentos = responseArrolamentos.data;

      if (pessoaResponse?.data?.tipoArrolamentoId) {
        const arrolamento = tiposArrolamentos?.find(
          (arrolamento) => arrolamento.id === pessoaResponse?.data?.tipoArrolamentoId
        );
        pessoaResponse.data.tipoArrolamentoId = arrolamento.tipo;
      }

      console.log(pessoas.data);
      setPessoa(pessoaResponse.data);
      setPessoas(pessoas.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    buscarDadosPessoa();
    // eslint-disable-next-line
  }, [id]);
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          <div className="d-flex justify-content-between">
            <span>Consultando {pessoa?.nome}</span>

            <div>
              <FontAwesomeIcon
                icon={faArrowLeft}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.goBack()}
              />

              <FontAwesomeIcon
                icon={faPen}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.push(`/cadastro/pessoas?id=${id}`)}
              />
            </div>
          </div>
        </HeaderTitle>
      </Header>

      <Row>
        <Col lg="12">
          <ListaDadosPessoa pessoa={pessoa} pessoas={pessoas} />
        </Col>
        <Col lg="12"></Col>
      </Row>
    </Container>
  );
};

export default DetalhePessoa;
