import React, { useEffect, useState } from "react";

import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { api } from "../services";

import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { ModalAuxiliar } from "../componentes/ModalAuxiliar/ModalAuxiliar";
import { FormularioRepertorio } from "../componentes/FormRepertorio/FormRepertorio";
import moment from "moment";

const offsetSaoPaulo = -3;

const ListagemRepertorios = ({ repertorios, setRepertorios, history }) => {
  const excluirRepertorio = async (id) => {
    const validarExclusao = window.confirm("Deseja realmente excluir o repertório?");
    if (!validarExclusao) return 0;
    try {
      await api.delete(`/Apresentacoes/${id}`);
      setRepertorios(
        repertorios.filter((repertorio) => repertorio.apresentacaoId !== id)
      );
      toastr.info("Repertório removida com sucesso!", "", { position: "bottom-left" });
    } catch (err) {
      console.log(err);
      alert(err.response.data.errors[0] || err.message);
    }
  };
  return (
    <Card>
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          Lista de Repertórios - {repertorios.length || 0}
        </h4>
      </CardHeader>
      <Table>
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Ativo</th>
            <th>Data</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {repertorios
            .slice(
              0,
              repertorios.length && document.getElementById("buscar-nome").value === ""
                ? 10
                : 2000
            )
            .map((repertorio, indice) => (
              <tr key={indice}>
                <td>{repertorio.descricao}</td>
                <td>{repertorio.ativo ? "Sim" : "Não"}</td>
                <td>{moment(repertorio.data).local().format("DD/MM/YYYY HH:mm")}</td>

                <td className="table-action">
                  <FontAwesomeIcon
                    icon={faEye}
                    fixedWidth
                    className="align-middle mr-3 hover"
                    onClick={() =>
                      history.push(`/repertorio/${repertorio.apresentacaoId}`)
                    }
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    fixedWidth
                    className="align-middle hover"
                    onClick={() => excluirRepertorio(repertorio.apresentacaoId)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

const Repertorios = () => {
  const [repertorios, setRepertorios] = useState([]);
  const [repertoriosDefault, setRepertoriosDefault] = useState([]);
  const [criarEditarRepertorio, setCriarEditarRepertorio] = useState({
    mostrar: false,
    tipo: null,
    repertorioSelecionado: null,
  });

  const requisitarRepertorios = async () => {
    try {
      const repertorios = await api.get("/Apresentacoes");

      if (repertorios.data) {
        setRepertorios(repertorios.data);
        setRepertoriosDefault(repertorios.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    requisitarRepertorios();
  }, []);
  const history = useHistory();

  const procurarRepertorio = async () => {
    try {
      const inputValue = $("#buscar-nome").val();

      const resultado = repertorios.filter((repertorio) =>
        repertorio.descricao.includes(inputValue)
      );
      if (inputValue === "" || !resultado || resultado.length === 0) {
        setRepertorios(repertoriosDefault);
      } else {
        setRepertorios(resultado);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container fluid>
      <Header>
        <div className="d-flex justify-content-between mb-2">
          <HeaderTitle>Procurar</HeaderTitle>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={() =>
              setCriarEditarRepertorio({
                ...criarEditarRepertorio,
                mostrar: true,
                tipo: 1,
              })
            }
          >
            Adicionar
          </button>
        </div>
        <div className="d-flex justify-content-between p-1 rounded">
          <input
            id="buscar-nome"
            type="text"
            className="form-control"
            placeholder="Nome..."
          />
          <button
            onClick={() => procurarRepertorio()}
            className="btn btn-outline-warning mx-1"
          >
            Procurar
          </button>
        </div>
      </Header>
      <Row>
        <Col lg="12">
          <ListagemRepertorios
            repertorios={repertorios}
            setRepertorios={setRepertorios}
            criarEditarRepertorio={criarEditarRepertorio}
            setCriarEditarRepertorio={setCriarEditarRepertorio}
            history={history}
          />
        </Col>
      </Row>
      <ModalAuxiliar
        data={criarEditarRepertorio}
        setData={setCriarEditarRepertorio}
        content={
          <FormularioRepertorio
            repertorios={repertorios}
            setRepertorios={setRepertorios}
            criarEditarRepertorio={criarEditarRepertorio}
          />
        }
      />{" "}
    </Container>
  );
};

export default Repertorios;
