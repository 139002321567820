import async from "../components/Async";

import {
  faBook,
  faChartPie,
  faCheckSquare,
  // faDesktop,
  faFile,
  faFlask,
  faHeart,
  faHome,
  faMapMarkerAlt,
  faTable,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

// Landing

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
// import SidebarLeft from "../pages/layouts/SidebarLeft";
// import SidebarRight from "../pages/layouts/SidebarRight";
// import ThemeModern from "../pages/layouts/ThemeModern";
// import ThemeClassic from "../pages/layouts/ThemeClassic";
// import ThemeDark from "../pages/layouts/ThemeDark";
// import ThemeLight from "../pages/layouts/ThemeLight";
// import ThemeBecape from "../pages/layouts/ThemeBecape";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Notifications from "../pages/ui-elements/Notifications";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";
import { Igreja } from "../Becape/pages/Igreja";
import Repertorios from "../Becape/pages/Repertorio";
/*
import Clientes from "../Becape/pages/Clientes";
import Contatos from "../Becape/pages/Contato";
import Estoques from "../Becape/pages/Estoques";
*/

const Pessoas = async(() => import("../Becape/pages/Pessoas"));
const MusicasPage = async(() => import("../Becape/pages/Musicas"));
//const Empresas = async(() => import("../Becape/pages/Empresas"));

// Pages
const Settings = async(() => import("../pages/pages/Settings"));
const Clients = async(() => import("../pages/pages/Clients"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Chat = async(() => import("../pages/pages/Chat"));

// Documentation
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const Plugins = async(() => import("../pages/docs/Plugins"));
const Changelog = async(() => import("../pages/docs/Changelog"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicElements = async(() => import("../pages/forms/BasicElements"));
const AdvancedElements = async(() => import("../pages/forms/AdvancedElements"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const AdvancedTables = async(() => import("../pages/tables/Advanced"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const IonIcons = async(() => import("../pages/icons/IonIcons"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Routes

const isBuildMode = process.env.NODE_ENV === "production";

/*const dynamicRoutes = [
  "Pessoas",
];*/

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  children: null,
  hideInBuildMode: true,
};

// const layoutRoutes = {
//   path: "/layouts",
//   name: "Layouts",
//   icon: faDesktop,
//   children: [
//     {
//       path: "/layouts/sidebar-left",
//       name: "Left Sidebar",
//       component: SidebarLeft,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/sidebar-right",
//       name: "Right Sidebar",
//       component: SidebarRight,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/theme-modern",
//       name: "Modern Theme",
//       component: ThemeModern,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/theme-classic",
//       name: "Classic Theme",
//       component: ThemeClassic,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/theme-dark",
//       name: "Dark Theme",
//       component: ThemeDark,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/theme-light",
//       name: "Light Theme",
//       component: ThemeLight,
//       hideInBuildMode: true,
//     },
//     {
//       path: "/layouts/theme-becape",
//       name: "Becape Theme",
//       component: ThemeBecape,
//       hideInBuildMode: true,
//     },
//   ],
//   hideInBuildMode: true,
// };

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
      hideInBuildMode: true,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
      hideInBuildMode: true,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
      hideInBuildMode: true,
    },
    {
      path: "*",
      name: "404 Page",
      component: Page404,
      hideInBuildMode: true,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
      hideInBuildMode: true,
    },
  ],
  hideInBuildMode: true,
};

const uiRoutes = {
  path: "/ui",
  name: "User Interface",
  header: "Elements",
  icon: faFlask,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts,
      hideInBuildMode: false,
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons,
      hideInBuildMode: false,
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards,
      hideInBuildMode: false,
    },
    {
      path: "/ui/general",
      name: "General",
      component: General,
      hideInBuildMode: false,
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid,
      hideInBuildMode: false,
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals,
      hideInBuildMode: false,
    },
    {
      path: "/ui/notifications",
      name: "Notifications",
      component: Notifications,
      hideInBuildMode: false,
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs,
      hideInBuildMode: false,
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: faChartPie,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs,
      hideInBuildMode: false,
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: faCheckSquare,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts,
      hideInBuildMode: false,
    },
    {
      path: "/forms/basic-elements",
      name: "Basic Elements",
      component: BasicElements,
      hideInBuildMode: false,
    },
    {
      path: "/forms/advanced-elements",
      name: "Advanced Elements",
      component: AdvancedElements,
      hideInBuildMode: false,
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups,
      hideInBuildMode: false,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors,
      hideInBuildMode: false,
    },
    {
      path: "/forms/validation",
      name: "Validation",
      component: Validation,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: faTable,
  children: [
    {
      path: "/tables/bootstrap",
      name: "Bootstrap",
      component: BootstrapTables,
      hideInBuildMode: false,
    },
    {
      path: "/tables/advanced-tables",
      name: "Advanced",
      component: AdvancedTables,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: faHeart,
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather,
      hideInBuildMode: false,
    },
    {
      path: "/icons/ion-icons",
      name: "Ion Icons",
      component: IonIcons,
      hideInBuildMode: false,
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: faCalendarAlt,
  component: Calendar,
  children: null,
  hideInBuildMode: false,
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: faMapMarkerAlt,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps,
      hideInBuildMode: false,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: faFile,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings,
      hideInBuildMode: false,
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients,
      badgeColor: "primary",
      badgeText: "New",
      hideInBuildMode: false,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      hideInBuildMode: false,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      hideInBuildMode: false,
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks,
      hideInBuildMode: false,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
      badgeColor: "primary",
      badgeText: "New",
      hideInBuildMode: false,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const documentationRoutes = {
  path: "/docs",
  name: "Documentation",
  icon: faBook,
  children: [
    {
      path: "/docs/getting-started",
      name: "Getting Started",
      component: GettingStarted,
      hideInBuildMode: false,
    },
    {
      path: "/docs/plugins",
      name: "Plugins",
      component: Plugins,
      hideInBuildMode: false,
    },
    {
      path: "/docs/changelog",
      name: "Changelog",
      component: Changelog,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};

const PessoasRota = {
  path: "/pessoas",
  name: "Pessoas",
  icon: faBook,
  component: Pessoas,
  children: null,
  hideInBuildMode: true,
};

const Ministerios = {
  path: "/igreja/ministerios",
  name: "Ministérios",
  icon: faBook,
  component: Igreja,
  children: null,
  hideInBuildMode: true,
};

const Eventos = {
  path: "/igreja/eventos",
  name: "Eventos",
  icon: faBook,
  component: Igreja,
  children: null,
  hideInBuildMode: true,
};

const Financeiro = {
  path: "/igreja/financeiro",
  name: "Financeiro",
  icon: faBook,
  component: Igreja,
  children: null,
  hideInBuildMode: true,
};

const Agendas = {
  path: "/igreja/agendas",
  name: "Agendas",
  icon: faBook,
  component: Igreja,
  children: null,
  hideInBuildMode: true,
};

const Eleicoes = {
  path: "/igreja/eleicoes",
  name: "Eleições",
  icon: faBook,
  component: Igreja,
  children: null,
  hideInBuildMode: true,
};
/*
const empresas = {
  path: "/empresas",
  name: "Empresas",
  header: "",
  icon: faHome,
  component: Empresas,
  children: null,
  hideInBuildMode: false,
};

const clientes = {
  path: "/clientes",
  name: "Clientes",
  header: "",
  icon: faHome,
  component: Clientes,
  children: null,
  hideInBuildMode: false,
};

const contatos = {
  path: "/contatos",
  name: "Contatos",
  header: "",
  icon: faHome,
  component: Contatos,
  children: null,
  hideInBuildMode: false,
};

const estoques = {
  path: "/estoques",
  name: "Estoques",
  header: "",
  icon: faHome,
  component: Estoques,
  children: null,
  hideInBuildMode: false,
};



/*
const dynamicImportedRoutes = dynamicRoutes.map(routeName => {
  const route = async(() => import(`../Becape/pages/${routeName}`));
  return { component: route, hideInBuildMode: true, name: routeName, path: `/${routeName.toLowerCase()}` };
}); */

const musicasRotas = {
  name: "Músicas",
  icon: faBook,
  children: [
    {
      path: "/igreja/musicas",
      name: "Listagem de Músicas",
      icon: faBook,
      component: MusicasPage,
      children: null,
      hideInBuildMode: true,
    },
    {
      path: "/igreja/repertorio",
      name: "Repertório",
      component: Repertorios,
      hideInBuildMode: true,
    },
  ],
  hideInBuildMode: true,
};

const Musicas = {
  path: "/igreja/musicas",
  name: "Músicas",
  icon: faBook,
  component: MusicasPage,
  children: null,
  hideInBuildMode: true,
};

const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank,
      hideInBuildMode: false,
    },
  ],
  hideInBuildMode: false,
};
// Dashboard specific routes
const rotas = [
  //...dynamicImportedRoutes,
  dashboardRoutes,
  PessoasRota,
  Ministerios,
  Eventos,
  Financeiro,
  Agendas,
  // Musicas,
  musicasRotas,
  Eleicoes,
  //layoutRoutes,
  uiRoutes,
  chartRoutes,
  formRoutes,
  tableRoutes,
  iconRoutes,
  calendarRoutes,

  mapRoutes,
  pageRoutes,
  documentationRoutes,
  privateRoutes,
  // authRoutes
];

// rotas router

// Landing specific routes

// Auth specific routes
export const page = [authRoutes];

// All routes
let dashboard = [...rotas];

if (isBuildMode) {
  dashboard = rotas.filter((route) => route.hideInBuildMode);
}
export { dashboard };
export default dashboard;
