import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { api } from "../services";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { EditorMusicaEstatico } from "../componentes/EditorMusicaEstatico";

const tonalidades = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "F#", "G", "Ab"];

const DetalheMusica = () => {
  const history = useHistory();
  const { id } = useParams();
  const [musica, setMusica] = useState(null);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const response = await api.get(`/Musicas/${id}`);
          console.log(response.data);

          setMusica(response.data);
        } catch (err) {
          console.log(err);
        }
      }
    })();
    //
  }, [id]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          <div className="d-flex justify-content-between">
            <span>Consultando {musica?.nome || ""}</span>

            <div>
              <FontAwesomeIcon
                icon={faArrowLeft}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.goBack()}
              />

              <FontAwesomeIcon
                icon={faPen}
                fixedWidth
                className="align-middle mr-3 hover"
                onClick={() => history.push(`/cadastro/musicas?id=${id}`)}
              />
            </div>
          </div>
        </HeaderTitle>
      </Header>

      <Row>
        <Col lg="12">
          <FormularioMusicas musica={musica} />
        </Col>
      </Row>
    </Container>
  );
};

const FormularioMusicas = ({ musica }) => {
  const { register, setValue } = useForm({});
  const [partes, setPartes] = useState([]);

  useEffect(() => {
    (async () => {
      if (musica) {
        Object.entries(musica).forEach(([key, value]) => {
          if (value !== null) {
            setValue(key, value);
          }
          if (key === "dado") {
            setPartes(JSON.parse(value) || []);
          }
        });
      }
    })();
    // eslint-disable-next-line
  }, [musica]);

  return (
    <Form>
      <Card>
        <CardHeader>
          <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
            MÚSICA
          </h4>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Nome</Label>
                  <input
                    className="form-control border-none"
                    type="text"
                    {...register("nome")}
                    required
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Descrição</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("descricao")}
                    required
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Tonalidade</Label>
                  <select
                    name=""
                    id=""
                    className="form-control"
                    {...register("tonalidade")}
                    required
                    disabled
                  >
                    <option value=""></option>

                    {tonalidades.map((tonalidade, index) => (
                      <option key={index} value={tonalidade}>
                        {tonalidade}
                      </option>
                    ))}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right">Tamanho da fonte*</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("tamanhoFonte")}
                    disabled
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={10}>
                  <Label className="text-sm-right ">Categoria*</Label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("categoria")}
                    disabled
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-8 w-100 ">
              MÚSICA
              <Label sm={2} className="text-sm-right"></Label>
              <EditorMusicaEstatico partes={partes} setPartes={setPartes} />
            </div>
          </div>
        </CardBody>
      </Card>
    </Form>
  );
};

export default DetalheMusica;
