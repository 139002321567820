import React from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { dashboard as dashboardRoutes, page as pageRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

import ScrollToTop from "../components/ScrollToTop";
import DetalhePessoa from "../Becape/pages/DetalhePessoa";
import CadastrarPessoas from "../Becape/pages/CadastrarPessoas";
import CadastrarMusica from "../Becape/pages/CadastrarMusica";
import DetalheMusica from "../Becape/pages/DetalheMusica";
import DetalheRepertorio from "../Becape/pages/DetalheRepertorio";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const isAuthenticated = () => {
  const token = document.cookie.split("; ").find((row) => row.startsWith("token="));
  return token ? true : false;
};

const ValidateTokenOnRouteChange = ({ children }) => {
  const location = useLocation();

  const isLoginPage = location.pathname === "/auth/sign-in";

  if (!isAuthenticated() && !isLoginPage) {
    return (window.location.href = "/auth/sign-in");
  }

  return <>{children}</>;
};

const Routes = () => (
  <Router>
    <ScrollToTop>
      <ValidateTokenOnRouteChange>
        <Switch>
          <Route path="/pessoa/:id" exact>
            <DashboardLayout>
              <DetalhePessoa />
            </DashboardLayout>
          </Route>
          <Route path="/musica/:id" exact>
            <DashboardLayout>
              <DetalheMusica />
            </DashboardLayout>
          </Route>
          <Route path="/repertorio/:id" exact>
            <DashboardLayout>
              <DetalheRepertorio />
            </DashboardLayout>
          </Route>
          <Route path="/cadastro/pessoas" exact>
            <DashboardLayout>
              <CadastrarPessoas />
            </DashboardLayout>
          </Route>
          <Route path="/cadastro/musicas" exact>
            <DashboardLayout>
              <CadastrarMusica />
            </DashboardLayout>
          </Route>
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, pageRoutes)}
        </Switch>
      </ValidateTokenOnRouteChange>
    </ScrollToTop>
  </Router>
);

export default Routes;
