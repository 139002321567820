import React, { useEffect, useState } from "react";
import { cnpj } from "cpf-cnpj-validator";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../services";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import moment from "moment";

const FormularioRepertorio = ({ repertorios, setRepertorios, criarEditarRepertorio }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const criarPessoa = async (data) => {
    try {
      const horario = moment;
      const post = {
        ...data,
        igrejaId: "00000000-0000-0000-0000-000000000000",
        data: moment(),
        // apresentacaoId:
      };
      console.log("Dados válidos:", post);

      setLoading(true);
      if (criarEditarRepertorio.tipo === 1) {
        const response = await api.post("/Apresentacoes", post);
        const novoRepertorio = response.data;
        setRepertorios([...repertorios, novoRepertorio]);
        toastr.success("Repertório cadastrado com sucesso!", "", {
          position: "bottom-left",
        });

        reset();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      const error = err.response.data.errors[0] || err.message;
      toastr.error("Erro ao manipular repertório!", error, {
        position: "bottom-left",
        timeOut: 15000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (criarEditarRepertorio?.tipo === 2) {
      Object.entries(criarEditarRepertorio.repertorioSelecionado).forEach(
        ([key, value]) => {
          setValue(key, value);
        }
      );
    } else {
      reset();
    }
  }, [criarEditarRepertorio]);

  return (
    <Card>
      <CardHeader>
        <h4 style={{ color: "#3e4676", lineHeight: 1.5 }} className="">
          {criarEditarRepertorio.repertorioSelecionado
            ? `Editando ${criarEditarRepertorio?.repertorioSelecionado?.descricao}`
            : "Cadastrar Repertório"}
        </h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(criarPessoa)}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Descrição</Label>
                <input
                  className="form-control"
                  type="text"
                  required
                  {...register("descricao")}
                />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Ativo</Label>
                <select {...register("ativo")} className="form-control">
                  <option value="1">SIM</option>
                  <option value="0">NÃO</option>
                </select>
              </FormGroup>
            </Col>

            <Button color="primary" className="ml-3 mt-2 align-self-center">
              {loading ? (
                <Spinner color="light">Loading...</Spinner>
              ) : criarEditarRepertorio.repertorioSelecionado ? (
                "Atualizar"
              ) : (
                "Cadastrar"
              )}
            </Button>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export { FormularioRepertorio };
