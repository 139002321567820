import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
export const ModalAuxiliar = ({ data, setData, content }) => {
  return (
    <div>
      <Modal
        isOpen={data.mostrar}
        toggle={() => setData({ ...data, mostrar: false })}
        size="lg"
      >
        <ModalHeader toggle={() => setData({ ...data, mostrar: false })}>
          {""}
        </ModalHeader>
        <ModalBody className="">{content}</ModalBody>
      </Modal>
    </div>
  );
};
